import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  // const imageStyle = {};
  const imageStyle = {
    // height: '550px',
    // width: '550px',
    // minWidth: '0',
  };

  function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
        return true;
      default:
        return false;
    }
  }

  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'webm':
        return true;
      default:
        return false;
    }
  }

  const {
    alt = 'I AM Studio',
    childImageSharp,
    image,
    backup,
    index,
    className,
  } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        style={imageStyle}
        alt={alt}
        className={className}
      />
    );
  } else if (!!childImageSharp) {
    return (
      <GatsbyImage
        image={childImageSharp.gatsbyImageData}
        style={imageStyle}
        alt={alt}
        className={className}
      />
    );
    // for Netlify CMS
  } else if (isImage(image)) {
    return (
      <div
        key={index}
        style={{ backgroundImage: `url(${image})` }}
        alt={alt}
        className={className}
      />
    );
  } else if (isVideo(image)) {
    return (
      <>
        <video
          style={imageStyle}
          className={`${className} portfolio-item-video`}
          playsInline
          autoPlay
          muted
          loop
          // controls
        >
          <source src={image} type="video/mp4" />
          <source src={image} type="video/webm" />
          <div
            key={index}
            style={{ backgroundImage: `url(${backup})` }}
            alt={alt}
            className={className}
          />
        </video>
        {/* <div
          key={index}
          style={{ backgroundImage: `url(${image})` }}
          alt={alt}
          className={`portfolio-item-image`}
        /> */}
      </>
    );
  } else {
    return null;
  }
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
