import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

import PreviewCompatibleImage from './PreviewCompatibleImage';

import iconArrowGold from '../img/iconarrow-thin.svg';

const Gallery = ({ imagesArray }) => {
  const [currIndex, setCurrIndex] = useState(0);
  const [galleryArray, setGalleryArray] = useState(imagesArray);
  const [isFullWidth, setIsFullWidth] = useState(false);

  function nextElem() {
    if (currIndex >= imagesArray.length - 1) return;
    let newArray = galleryArray;
    const newlast = newArray.shift();
    newArray = [...newArray, newlast];
    setGalleryArray(newArray);
    setCurrIndex((currIndex + 1) % newArray.length);
  }

  function prevElem() {
    if (currIndex <= 0) return;
    let newArray = galleryArray;
    const newfirst = newArray.pop();
    newArray = [newfirst, ...newArray];
    setGalleryArray(newArray);
    setCurrIndex(currIndex - 1 < 0 ? newArray.length - 1 : currIndex - 1);
  }

  // GSAP
  gsap.registerPlugin();

  const galleryRef = useRef(null);
  const galleryTl = useRef();
  galleryTl.current = gsap.timeline();

  // useEffect(() => {
  //   let windowInnerWidth =
  //     typeof window !== `undefined` ? window.innerWidth : null;
  //   let documentChecked = typeof document !== `undefined` ? document : null;
  //   // Animate Stats
  //   const q = gsap.utils.selector(galleryRef);

  //   let galleryImages = q('.gallery-image');

  //   galleryImages.forEach((image, i) => {
  //     // Do the parallax effect on each image

  //     // image.style.backgroundPosition = ` ${-windowInnerWidth / 2}px 50%`;
  //     // image.style.width = `${windowInnerWidth}px`;

  //     gsap.to(image, {
  //       // backgroundPosition: ` ${windowInnerWidth / 2}px 50%`,
  //       ease: 'none',
  //       scrollTrigger: {
  //         trigger: image,
  //         scrub: true,
  //       },
  //     });
  //   });
  // });

  return (
    <section className="images-gallery" ref={galleryRef}>
      <div
        tabIndex={0}
        role={'button'}
        onClick={() => setIsFullWidth(!isFullWidth)}
        onKeyDown={() => setIsFullWidth(!isFullWidth)}
        className={`gallery-image gallery-image-0 ${
          isFullWidth && 'gallery-fullscreen'
        }`}
      >
        {galleryArray &&
          galleryArray.map((image, index) => {
            if (index >= 1) return null;
            return (
              <PreviewCompatibleImage
                key={image.alt + index}
                imageInfo={{
                  image: image.image,
                  alt: `Gallery Image -  ${index}`,
                  className: `gallery-item `,
                }}
              />
            );
          })}
      </div>
      <div className={`gallery-image gallery-image-1`}>
        {galleryArray &&
          galleryArray.map((image, index) => {
            if (index === 0 || index >= 2) return null;
            return (
              <PreviewCompatibleImage
                key={image.alt + index}
                imageInfo={{
                  image: image.image,
                  alt: `Gallery Image -  ${index + 1}`,
                  className: `gallery-item `,
                }}
              />
            );
          })}
      </div>
      <div className="gallery-console">
        <div className="gallery-label">
          <p className="gallery-indicator">
            {currIndex + 1}/{galleryArray && galleryArray.length - 1}
          </p>
        </div>
        <div className="gallery-navigation">
          <img
            role="button"
            className="gallery-prev"
            src={iconArrowGold}
            alt="Previous - Gallery Navigation"
            onClick={(e) => prevElem(e)}
            onKeyDown={(e) => prevElem(e)}
            // style={{ width: '60px' }}
          />
          <img
            role="button"
            className="gallery-next"
            src={iconArrowGold}
            alt="Next - Gallery Navigation"
            onClick={(e) => nextElem(e)}
            onKeyDown={(e) => nextElem(e)}
            // style={{ width: '60px' }}
          />
        </div>
      </div>
    </section>
  );
};

Gallery.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    })
  ),
};

export default Gallery;
