import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Layout from '../components/Layout';
import Gallery from '../components/Gallery';
import Teamgallery from '../components/Teamgallery';

import iconArrowThin from '../img/iconarrow-thin.svg';
import iconArrowGold from '../img/iconarrow-gold.svg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
import be from '../img/social/be.svg';

// eslint-disable-next-line
export const AboutPageTemplate = ({ data }) => {
  const imagesArray = data.header.gallery;
  const logosArray = data.clients.logos;
  // const logosArray = data.clients.logos.reduce((prev, curr) => {
  //   return [...prev, { image: getImage(curr.image), alt: curr.alt }];
  // }, []);
  const teamArray = data.team.members;

  // const teamArray = data.team.members.reduce((prev, curr) => {
  //   return [
  //     ...prev,
  //     {
  //       image: getImage(curr.image),
  //       name: curr.name,
  //       function: curr.function,
  //       description: curr.description,
  //     },
  //   ];
  // }, []);

  // GSAP
  gsap.registerPlugin(ScrollTrigger);

  const statsRef = useRef(null);

  useEffect(() => {
    // Animate Stats
    const q = gsap.utils.selector(statsRef);
    let statsValues = q('.stats-value');
    statsValues.forEach((statValue) => {
      gsap.from(statValue, {
        scrollTrigger: {
          trigger: statValue,
          scrub: 0.3,
          start: 'top bottom',
          end: 'center bottom',
        },
        innerText: statValue.innerText - 10,
        opacity: 0,
        ease: 'circ.in',
        snap: 'innerText',
      });
    });
  });

  return (
    <main className="about-page">
      <div className="topfade"></div>
      <div className="grain"></div>
      <div className="page-indicator">STUDIO</div>
      <section className="page-section about-section section-alt">
        <div className="heading-container heading-container-first">
          <img
            className="iam-link-arrow"
            src={iconArrowThin}
            alt="Title Arrow - Decoration"
          />
          <h2 className="heading-title">
            <span className="heading-title-alt">What I am</span> is who we are
          </h2>
        </div>
        <p className="section-description">{data.header.description}</p>
      </section>
      <Gallery imagesArray={imagesArray} />

      {/* Stats Section */}
      <section className="page-section about-section stats-section">
        <div className="stats-container" ref={statsRef}>
          {data.stats.stats.map((stat, index) => {
            return (
              <div
                key={Math.random() * index}
                className={`stats-item ${index === 0 && 'stats-item-first'} ${
                  index === data.stats.stats.length - 1 && 'stats-item-last'
                }`}
              >
                <div className="iam-category" to="/creative">
                  <img
                    className="iam-stats-arrow"
                    src={iconArrowGold}
                    alt="I AM"
                    style={{ width: '20px' }}
                  />
                  <p className="stats-label">{stat.label}</p>
                </div>
                <p className="stats-value">{stat.number}</p>
              </div>
            );
          })}
        </div>
      </section>

      {/* Team */}
      <section className="page-section about-section section-alt">
        <div className="heading-container">
          <img
            className="iam-link-arrow"
            src={iconArrowThin}
            alt="Title Arrow - Decoration"
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Team</span>
          </h2>
        </div>
        <p className="section-description section-alt">
          {data.team.description}
        </p>
      </section>
      <Teamgallery teamArray={teamArray} />

      {/* Services */}
      <section className="page-section about-section">
        <div className="heading-container">
          <img
            className="iam-link-arrow"
            src={iconArrowThin}
            alt="Title Arrow - Decoration"
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Services</span>
          </h2>
        </div>
        <p className="section-description">{data.services.description}</p>
        <ul>
          {data.services.services.map((service, index) => {
            const count = index + 1;
            return (
              <li className="service-container" key={Math.random() * index}>
                <p className="service-counter">
                  {count.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </p>
                <p className="service-item">{service}</p>
              </li>
            );
          })}
        </ul>
      </section>

      {/* Clients */}
      <section className="page-section about-section">
        <div className="heading-container">
          <img
            className="iam-link-arrow"
            src={iconArrowThin}
            alt="Title Arrow - Decoration"
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Clients</span>
          </h2>
        </div>
        <p className="section-description">{data.clients.description}</p>

        {/* Logos Array */}
        <ul className="client-list">
          {logosArray &&
            logosArray.map((logo, index) => (
              <img
                key={Math.random() * index}
                src={logo.image}
                alt={logo.alt}
                className="client-logo"
              />
            ))}
        </ul>
      </section>

      {/* Here */}
      <section className="page-section about-section">
        <div className="heading-container">
          <img
            className="iam-link-arrow"
            src={iconArrowThin}
            alt="Title Arrow - Decoration"
          />
          <h2 className="heading-title">
            I am <span className="heading-title-alt">Here</span>
          </h2>
        </div>
        <p className="section-description">{data.here.description}</p>

        {/* Liste Logos */}
        <a className="contact-detail-value" href={`mailto:${data.here.email}`}>
          {data.here.email}
        </a>
        <div className="contact-detail contact-detail-social-container ">
          <a
            className="contact-detail-social facebook"
            href={data.here.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebook}
              alt="I am Studio - facebook"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
          <a
            className="contact-detail-social instagram"
            href={data.here.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              alt="I am Studio - instagram"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
          <a
            className="contact-detail-social be"
            href={data.here.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={be}
              alt="I am Studio - be"
              style={{ height: '25px', width: '25px' }}
            />
          </a>
        </div>
      </section>
    </main>
  );
};

const aboutTitle = "I AM STUDIO";
const aboutDescription = "I am Studio is a multidisciplinary creative agency. We have decades of experience creating all possible visual forms of design. With our wide range of skills set we work to make the world a visually better place.";

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet titleTemplate={post.frontmatter.meta?.title || aboutTitle}>
        <title>{post.frontmatter.meta?.title || aboutTitle}</title>
        <meta name="info" content={post.frontmatter.meta?.description || aboutDescription} />
        <meta name="description" content={post.frontmatter.meta?.description || aboutDescription} />
        <meta property="og:title" content={post.frontmatter.meta?.title || aboutTitle} />
        <meta property="og:description" content={post.frontmatter.meta?.description || aboutDescription} />
      </Helmet>
      <AboutPageTemplate data={post.frontmatter} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        meta {
          title
          description
        }
        header {
          description
          heading
          gallery {
            alt
            image
          }
        }
        stats {
          stats {
            label
            number
          }
        }
        team {
          heading
          description
          members {
            name
            function
            traits
            image
          }
        }
        services {
          heading
          description
          services
        }
        clients {
          heading
          description
          logos {
            alt
            image
          }
        }
        here {
          heading
          description
          email
          facebook
          instagram
          twitter
        }
      }
    }
  }
`;
