import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PreviewCompatibleImage from './PreviewCompatibleImage';

import iconArrowGold from '../img/iconarrow-thin.svg';
// import iconArrowIam from '../img/iconarrow_nav.svg';
import iconClose from '../img/icon-close.svg';

const Teamgallery = ({ teamArray }) => {
  const [teamMemberArray, setteamMemberArray] = useState(teamArray);
  const [currIndex, setCurrIndex] = useState(0);

  function nextElem() {
    if (currIndex >= teamArray.length - 2) return;
    let newArray = teamMemberArray;
    const newlast = newArray.shift();
    newArray = [...newArray, newlast];
    setteamMemberArray(newArray);
    setCurrIndex((currIndex + 1) % newArray.length);
  }

  function prevElem() {
    if (currIndex <= 0) return;
    let newArray = teamMemberArray;
    const newfirst = newArray.pop();
    newArray = [newfirst, ...newArray];
    setteamMemberArray(newArray);
    setCurrIndex(currIndex - 1 < 0 ? newArray.length - 1 : currIndex - 1);
  }

  return (
    <section className="about-section members-section">
      <ul className="team-container">
        {/* Team Gallery */}
        {teamMemberArray &&
          teamMemberArray.map((member, index) => {
            // if (index >= 3) return null;
            return (
              <TeamMember
                key={Math.random() * index}
                functions={{ nextElem, prevElem }}
                teamMember={member}
                indexMember={index}
              />
            );
          })}
      </ul>
    </section>
  );
};

Teamgallery.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    })
  ),
};

export default Teamgallery;

export const TeamMember = ({ teamMember, indexMember, functions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const TeamNav = () => {
    return (
      <div className="team-navigation">
        <img
          className="gallery-prev"
          src={iconArrowGold}
          alt="Previous - Team Navigation"
          onClick={() => functions.prevElem()}
          onKeyPress={() => functions.prevElem()}
          style={{ width: '60px' }}
        />
        <img
          className="gallery-next"
          src={iconArrowGold}
          alt="Next - Team Navigation"
          onClick={() => functions.nextElem()}
          onKeyPress={() => functions.nextElem()}
          style={{ width: '60px' }}
        />
      </div>
    );
  };

  return (
    <li
      key={Math.random() * indexMember}
      className={`team-member team-member-${indexMember}`}
    >
      {indexMember === 1 && <TeamNav />}
      <PreviewCompatibleImage
        imageInfo={{
          image: teamMember.image,
          alt: `Team Member Image - ${indexMember} | ${teamMember.alt}`,
          className: 'gallery-item',
        }}
      />
      <div className="team-member-container">
        <p className="team-member-name">{teamMember.name}</p>
        {teamMember.traits && (
          <img
            className={`team-member-more ${isOpen && 'team-member-active'}`}
            src={iconClose}
            alt="Title Arrow - Decoration"
            style={{ height: '45px', width: '45px' }}
            onClick={() => setIsOpen(!isOpen)}
            onKeyPress={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
      <p className="team-member-function">{teamMember.function}</p>

      {
        <ul
          className={`team-member-description ${
            isOpen && 'team-member-description-visible'
          }`}
        >
          {/* <div className="iam-indicator">
            <p>I AM</p>
            <img
              className="iam-indicator-arrow"
              src={iconArrowIam}
              alt="I AM"
              style={{ width: '30px' }}
            />
          </div> */}
          {teamMember.traits &&
            teamMember.traits.map((trait, indexMember) => {
              return <li key={Math.random() * indexMember}>{trait}</li>;
            })}
        </ul>
      }
    </li>
  );
};
